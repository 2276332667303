	var canBeLoaded = true, // this param allows to initiate the AJAX call only if necessary
	    bottomOffset = 200; // the distance (in px) from the page bottom when you want to load more posts

      var pagina= 2;

      var isHome = false;






            jQuery(window).scroll(function(){


              amountscrolled();


              if(jQuery('body').hasClass('home')){
                isHome=true;
              }



             // console.log("document).height " +jQuery(document).height()+" " +(jQuery(document).height() - bottomOffset) +" / " + jQuery(document).scrollTop() );
              /*var data = {
                'action':'loadmore',
                'query': misha_loadmore_params.posts,
                'page' : misha_loadmore_params.current_page
              };*/
             // if( jQuery(document).scrollTop() > ( jQuery(document).height() - bottomOffset ) && canBeLoaded == true ){

             /*
$.ajax({
			url: ajaxpagination.ajaxurl,
			type: 'post',
			data: {
				action: 'ajax_pagination',
				query_vars: ajaxpagination.query_vars,
				page: page
			},
			success: function( html ) {
				$('#main').find( 'article' ).remove();
				$('#main nav').remove();
				$('#main').append( html );
			}
		})
             */

    //http://localhost:3000/lavernia-cienfuegoscom/wp-admin/admin-ajax.php


             	 if( amountscrolled() >=50 && canBeLoaded == true ){

                    canBeLoaded = false;
                    console.log(">>CARGO CAT " +  catLoad + " TAXI " +taxo+" DESDE " + f_rom + " PAGE " + MyAjax.url +" PAGINA " + pagina + "NONCE "+MyAjax.nonce );


                    console.log('MyAjax.url ' + MyAjax.url);
                    console.log('MyAjax.nonce ' + MyAjax.nonce);

/*

*/

                    jQuery.post(MyAjax.url, {pagina: pagina , nonce : MyAjax.nonce, action : 'mostrar_mas_work' ,desde : jQuery('.itm_proyecto').length ,  categoria:  catLoad , isHome:isHome  }, function(response) {

                        // console.log("RESPONSE >>>>");
                        // console.log(response);

                         var jQuerycontent = jQuery(response);



                        // console.log("jQuerycontent");
                        // console.log(jQuerycontent);



                        jQuery('#cargados').append(jQuerycontent);

                        /*
              if(jQuery('body').hasClass('home')){
                isHome=true;
              }
                        */


                        if(jQuery('body').hasClass('home')){  //SI  HOME


                          console.log('RESPONSE IS HOME >>>');

                          console.log('RESPONSE IS jQuerycontent');

                          console.log(jQuerycontent);

                          //jQuery('#cargados').append(jQuerycontent);


                        }else{




                            jQuery('#cargados').find('.itm_proyecto').addClass('op_0');


                            jQuery('#cargados .img_q').each(function(){

                                console.log("PRE ANCHO NO HOME " +  jQuery(this).width()  );

                                //jQuery(this).width( Math.floor(jQuery(this).width()) );


                                jQuery(this).width(anchoItem);

                                console.log("ANCHO " +  jQuery(this).width()  );

                                jQuery(this).height( Math.floor( jQuery(this).width() ) );

                            });

                            jQuery('#cargados .img_r').each(function(){

                                    console.log("PRE ANCHO R " +  jQuery(this).width()  );

                                  // jQuery(this).width(Math.floor(jQuery(this).width()));

                                  // jQuery(this).width(anchoItem/2);

                                  if(jQuery(document.window)<480){

                                  // jQuery(this).width(anchoItem);
                                  }

                                    console.log("ANCHO R " +  jQuery(this).width()  );

                                  // jQuery(this).height( Math.floor( (jQuery(this).width()*2)  ) + arreglo );

                                  // jQuery(this).height( Math.floor( (jQuery(this).width()*(1225/900))  ) + arreglo );

                                  if(jQuery('body').hasClass('tax-works')){
                                    jQuery(this).height( Math.floor( (jQuery(this).width()*2)  ) + arreglo );
                                  }else{

                                      console.log(' #cargados .img_r ');

                                      jQuery(this).height( Math.floor( (jQuery(this).width()*(1225/900))  ) + arreglo );
                                  }



                            });





                        }




                        if(jQuerycontent){

                          if(jQuery('body').hasClass('home')){


                              console.log('!!!!!!!!!ESTOY EN HOME');

                              canBeLoaded = true; /* */
                              pagina++;


                          }else{ //NOT HOME



                                grid.append(jQuerycontent).packery( 'appended', jQuerycontent );
                                grid.packery({gutter:0, percentPosition: true, transitionDuration: 0  });
                                grid.packery('shiftLayout');


                                  jQuery('.op_0.img_q').each(function(){

                                      console.log("PRE ANCHO " +  jQuery(this).width()  );

                                      //jQuery(this).width( Math.floor(jQuery(this).width()) );

                                    // jQuery(this).width(anchoItem);

                                      console.log("ANCHO " +  jQuery(this).width()  );

                                      jQuery(this).height( Math.floor( jQuery(this).width() ) );

                                  });

                                  jQuery('.op_0.img_r').each(function(){

                                    console.log("PRE ANCHO R LOADED" + jQuery(this).find('.nom_proy_2020').text() +" / "  +  jQuery(this).width()  );

                                    //jQuery(this).width(Math.floor(jQuery(this).width()));
                                    jQuery(this).width(anchoItem/2);

                                    if(jQuery(document.window)<480){

                                    // jQuery(this).width(anchoItem);
                                    }

                                      console.log("ANCHO R LOADED " + jQuery(this).find('.nom_proy_2020').text() +" / "  +  jQuery(this).width()  );

                                    // jQuery(this).height( Math.floor( (jQuery(this).width()*2)  ) + arreglo );
                                    //jQuery(this).height( Math.floor( (jQuery(this).width()*(1225/900))  ) + arreglo );


                                    if(jQuery('body').hasClass('tax-works')){
                                      jQuery(this).height( Math.floor( (jQuery(this).width()*2)  ) + arreglo );
                                    }else{
                                        jQuery(this).height( Math.floor( (jQuery(this).width()*(1225/900))  ) + arreglo );
                                    }



                                  });

                                    var miPackTemp = setInterval(makePack, 300);
                                    var tempCont= 0;

                                    function makePack(){

                                          grid.packery({gutter:0, percentPosition: true, transitionDuration: 0  });
                                          grid.packery('shiftLayout');
                                          console.log("MAKE PACK");

                                      if(tempCont >= 3){

                                        clearInterval(miPackTemp);
                                        tempCont= 0;

                                      }

                                      tempCont++;

                                    }



                                    canBeLoaded = true; /* */
                                    pagina++;


                                    jQuery('.op_0').each(function(){

                                      jQuery(this).css('opacity', 0);
                                      jQuery(this).removeClass('op_0');

                                      //jQuery(this).fadeIn(400, function(){ });
                                      jQuery(this).delay(200).animate({
                                        opacity:1

                                      } , 500);

                                    });

                                  //  alert(pagina);




                          }



                        }




                       // junta();


                    });

					 /**/

              }
            });


function amountscrolled(){
    var winheight = jQuery(window).height()
    var docheight = jQuery(document).height()
    var scrollTop = jQuery(window).scrollTop()
    var trackLength = docheight - winheight
    var pctScrolled = Math.floor(scrollTop/trackLength * 100) // gets percentage scrolled (ie: 80 NaN if tracklength == 0)
   // console.log(pctScrolled + '% scrolled')
    return pctScrolled;
}

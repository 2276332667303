/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var anchoGrid;
var  grid;

var caja="caja";

var anchoItem;

var arregloQuad, arreglo = 0;

var altWrapVar = 50;

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

  function arreglosSize(){
    //Columna Fixed





    if($('.col_desc .in_fixed').height()>$('.col_imgs').height()  ||  $('.col_desc .in_fixed').height() > $(window).height() ){

        $('.col_desc .in_fixed').removeClass('in_fixed');


       ////console.log("NO FIXED DESC " + $('.col_desc').height() + " / IMGS "+$('.col_imgs').height());

    }else{
      ////console.log("FIXED" +  $('.col_desc .in_fixed').outerHeight() +" W "+ $(window).height() );
    }

/**/





    $('.in_fixed').each(function(){
      $(this).width( $(this).parent().width() ) ;
    });

//alert(  "FIXED" +  $('.col_desc .in_fixed').outerHeight() +" W "+ $(window).height()   );


    if(  $('.col_desc .in_fixed').outerHeight() > $(window).height() ){

        $('.col_desc .in_fixed').removeClass('in_fixed').addClass('in_rel');


       ////console.log("NO FIXED DESC " + $('.col_desc').height() + " / IMGS "+$('.col_imgs').height());

    }else{
      ////console.log("FIXED" +  $('.col_desc .in_fixed').outerHeight() +" W "+ $(window).height() );
    }



  /*  if($('.grid').length > 0){

          anchoGrid  = $('.grid').width();

          $('.grid-item').each(function(){
              $(this).width(0);
              $(this).width(  Math.floor(anchoGrid/2) );
          });

    }*/



    //$('.wrap').css('padding-top', $('.banner').height()); PRE 2022

    //root.style.setProperty('--padTopWrap', (jQuery('.banner').height() + altWrapVar) +'px' );



  }

  var Sage = {
    // All pages
    'common': {
      init: function() {

        var miFormCont = jQuery('.wysija-paragraph');

        if(miFormCont.length>0){
          miFormCont.each(function(index){
            //console.log( index + ": " + $( this )  );
            //console.log($(this).next().andSelf().wrapAll('<div id="divform_'+  index  +'" style=" " class="test_form"/>'));
            ////console.log($(this).next().next());

          })

          jQuery('.test_form').each(function(index){

            jQuery(this).next().appendTo(jQuery(this));



          })

        }




        //col-12 col-sm-6 col-md-4 col-xl-3


          var tempo;
          var actual;
          var anterior;

        anchoItemCalc();



        // JavaScript to be fired on all pages

        ////console.log("COL DESC " + $('.col_desc .in_fixed').height() + " / "+ $('.col_imgs').height());


      //  alert($('.col_imgs').width());


        /*ya no hace falta con todo centrado */
        /*
        if($('.col_imgs').width()%2!==0){
          $('.col_imgs_menu, .col_imgs').css( 'padding-left','9px' );

        }*/

      //  alert($('.col_imgs').width());


         arreglosSize();

         $('.bot_menu_header').on('click', function(ev){
          //console.log('CLICK SACO MENÚ');
            ev.preventDefault();
            $('.nav-primary').slideToggle();
         });


          var lang = $('html')[0].lang ;

         $(".fancybox").fancybox({afterShow: function(){



            if( lang.indexOf("es")!==-1 ){
                  $('.fancybox-next span').text("Siguiente");
                  $('.fancybox-prev span').text("Anterior");
                  $('.fancybox-close').text("Cerrar");
            }



          } });

         //$('.wrap').css('padding-top', $('.banner').height() );

         //root.style.setProperty('--padTopWrap', (jQuery('.banner').height() + altWrapVar) +'px' );

         //MENU HOVER

         $('.dropdown').each(function(i){

            $(this).find('.dropdown-menu').css('min-width', $(this).width() );

            $(this).find('.dropdown-menu a').wrap('<li class="menu_drop"></li>');

         });

        // jQuery('.dropdown ').on('click', clickDrop);

        jQuery('.dropdown a').on('click', clickDrop);

        jQuery('.dropdown a').on('mouseleave', salgoDrop);
        jQuery('.menu-item > a').on('mouseenter', entroMenuBot);

         jQuery('.dropdown-menu').on('mouseleave', function(ev){
           //console.log(">>>>>> SALGO!!!!!!!!!!!!");

           actual = jQuery(ev.currentTarget).parents('.show');

          //console.log('SALGO ACTUAL');
          //console.log(actual);
          //console.log('SALGO ACTUAL PARENT')
          //console.log(actual.parent());

           tempo = setTimeout(cierraOpciones, 300);

           //jQuery('body').removeClass('menu_open');

         // jQuery(ev.currentTarget).parents('.show').trigger('click');



         });

         function entroMenuBot(ev){
          //console.log('AAAAAAENTRO' );
          //console.log('---- 0 ----' );
          //console.log(ev.currentTarget);
          //console.log('---- 1 ----' );
          actual = jQuery('.show');
          //console.log( actual);
          //console.log('---- 2 ----' );
          actual = jQuery('.show').siblings('a');
          //console.log(actual);
          tempo = setTimeout(cierraOpciones, 300);
         }

         function salgoDrop(ev){

         // //console.log('SALGO',ev.currentTarget)


         }

         function clickDrop(ev){

              //jQuery('body').addClass('menu_open');



              //console.log('clickDrop clicko!!!!!!!!!!!!!!!!!!!!!');
              //console.log(actual);


              //console.log('ev.currentTarget');
              //console.log(ev.currentTarget);
              //console.log("jQuery('.open')");
              //console.log(jQuery('.open').eq(0));

              var op1 = document.querySelector('.open');

              if(ev.currentTarget != op1){
                //console.log('ev.currentTarget != op1');
                jQuery('.open').removeClass('open');
                jQuery('body').removeClass('menu_open');
              }else{

                //console.log('NO ev.currentTarget != op1');

                jQuery('.open').removeClass('open');
                jQuery('body').removeClass('menu_open');

                //console.log('chapo!!');
                return;
              }

              if(tempo){

                  clearTimeout(tempo);

              }

              jQuery(ev.currentTarget).toggleClass('open');

              jQuery('body').toggleClass('menu_open');

              if(jQuery('body').hasClass('menu_open')){
               // root.style.setProperty('--alturaMenu', jQuery('.dropdown-menu').eq(0).height() +'px' );
              }

              if(actual   ){

                //cierraOpciones();
               // anterior = actual;


              }else{
                actual = jQuery(ev.currentTarget) ;




               // jQuery('body').addClass('menu_open');
              }


              //--alturaMenu






         }

         function cierraOpciones(){

          //console.log('cierraOpciones  actual');

          //console.log(actual);

          if(actual){

            actual.removeClass('open');


          }



           jQuery('body').removeClass('menu_open');

           jQuery('.show').removeClass('show');
           jQuery('.open').removeClass('open');
          jQuery(actual).parent().find('.dropdown-menu').removeClass('show');
          actual='';

         // jQuery(actual).trigger('click');
          clearTimeout(tempo);



         }





     /*       if((navigator.userAgent.indexOf('iPad')===-1) &&  (navigator.userAgent.indexOf('iPhone')===-1) &&  (navigator.userAgent.indexOf('Android')===-1)   ){


       $('.dropdown').on('hover', function(){

                $(this).toggleClass('open');

             }, function(){

                $(this).toggleClass('open');

             });

        }*/
          /* */

          $('.social_out').attr('target','_blank');



          //Check is ABOUT PAGE

          if(jQuery('body').hasClass('page-template-template-about-2024')){

            hazAbout();
          }



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_work': {
      init: function() {
        // JavaScript to be fired on the about us page
        function WordCount(str) {
          return str.split(" ").length;
        }
        function arrText(str){
          return str.split(" ");
        }

        var limitWord = 70;

        var totalWords = jQuery('.limit_content').text();
        var cantidadWords = WordCount(totalWords);
        var arrWord = arrText(totalWords);

        var contenidoLimitado = "";
        if(cantidadWords > limitWord ){

            totalWords = jQuery('.limit_content  p:first-of-type').text();
            cantidadWords = WordCount(totalWords);
          //console.log(totalWords);

            arrWord = arrText(totalWords);

            if(cantidadWords<70){
              limitWord = cantidadWords;
            }


          for(var p=0; p<limitWord; p++){
            contenidoLimitado +=arrWord[p]+" ";
          }


          jQuery('.limit_content').html(contenidoLimitado);
          jQuery('.showmore_w').removeClass('dispNone');

          jQuery('.showmore_w').on('click', function(ev){
            ev.preventDefault();
            jQuery(ev.currentTarget).toggleClass('dispNone');
            jQuery('.limit_content').toggleClass('dispNone');
            jQuery('.content_exp').toggleClass('dispNone');
          })

        }

        //alert(WordCount(totalWords));


        /*
          const swiper = new Swiper('.swiper-container', {
              // Optional parameters
              loop: true,
              effect: 'fade',
              speed:0,
              autoplay: {
                  delay: 1000,
                },

              // If we need pagination
              pagination: {
                el: '.swiper-pagination',
              },

              // Navigation arrows
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },

              // And if we need scrollbar
              scrollbar: {
                el: '.swiper-scrollbar',
              },
            });
        */

        var arrSwipers = [];
        var tmpSwiper;

        jQuery('.galeria_animada').each(function(){


            var delay = jQuery(this).data('tiempo');
            var speed =  jQuery(this).data('efecto');


              tmpSwiper = new Swiper(this, {

              loop:true,
              effect:'fade',
              speed:speed,
              autoplay:{
                delay:delay,
                disableOnInteraction:false
              }

            });

           arrSwipers.push(tmpSwiper);

          //  tmpSwiper=null;



        })



      }
    },
    'page_template_template_equipo':{
      init:function(){

        $('.cruz_open').on('click', function(ev){

            ev.preventDefault();
            $(this).toggleClass('cruz_close');

            $(this).next().slideToggle('normal');

        });


      }
    },
    'tax_works':{
      init:function(){


 /*
          var bLazy = new Blazy({
            success: function(ele){
               grid.packery({gutter:0});
            }
        });

          anchoGrid = $('.grid').width();



         grid =  $('.grid').imagesLoaded( function() {

           $('.grid-item').each(function(){

                $(this).width(  Math.floor(anchoGrid/2) );
              //  ////console.log("ANCHO "+$(this).width() );
            });

           grid.packery({gutter:0});

        });
*/




/*
        jQuery('.img_q').each(function(){

          jQuery(this).height(jQuery(this).width());

        });

        jQuery('.img_r').each(function(){

          jQuery(this).height( (jQuery(this).width()*2)  );

        });

         */




          // $('.grid').packery({gutter:0, percentPosition: true  });
          //  $('.grid').packery('shiftLayout');


        //alert(grid);








      },
      finalize: function() {


      }
    }
  };




  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');




     // $('.marquito').css('display','none');


    // alert("COL DESC LOAD TEXTO " + $('.col_desc .in_fixed').height() + " / IMAGENES"+ $('.col_imgs').height());

    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


function compruebaTam(){



    if( jQuery('.col_desc .in_fixed').height()> jQuery('.col_imgs').height()  ||   jQuery('.col_desc .in_fixed').height() >  jQuery(window).height() ){

        jQuery('.col_desc .in_fixed').removeClass('in_fixed');
      //////console.log("NO FIXED DESC " + $('.col_desc').height() + " / IMGS "+$('.col_imgs').height());

    }else{
     // ////console.log("FIXED");
    }
        jQuery('.in_fixed').each(function(){
       jQuery(this).width( jQuery(this).parent().width() ) ;
    });

     ////console.log("COMPRUEBO "+ jQuery('.col_desc').height()+" "+ jQuery('.col_imgs').height() );

}

window.onload=function(){

        compruebaTam();

       var setTime =  setTimeout(compruebaTam, 3000);

       jQuery('.loading').fadeOut(600, function(){

        jQuery('.loading').remove();

       });


         //jQuery('.wrap').css('padding-top', jQuery('.banner').height()); //pre 2022

         //root.style.setProperty('--padTopWrap', (jQuery('.banner').height() + altWrapVar) +'px' );



         ////console.log("COL DESC LOAD TEXTO " + jQuery('.col_desc .in_fixed').height() + " / IMAGENES"+ jQuery('.col_imgs').height());


        if(jQuery('.col_desc .in_fixed').height()>jQuery('.col_imgs').height()  ||  jQuery('.col_desc .in_fixed').height() > jQuery(window).height()  ){

            jQuery('.col_desc .in_fixed').removeClass('in_fixed');
          //////console.log("NO FIXED DESC " + jQuery('.col_desc').height() + " / IMGS "+jQuery('.col_imgs').height());

        }else{
         // ////console.log("FIXED");
         jQuery('.col_desc .pongo_in_fixed').addClass('in_fixed');
            jQuery('.in_fixed').each(function(){
              jQuery(this).width( jQuery(this).parent().width() ) ;
            });
        }

        jQuery('.verti_img').each(function(){
         // jQuery(this).find('img').height(jQuery(this).find('img').height() -1 );

        });



        //GRID


         arreglosGrid();

          //alert(jQuery('.img_r').height() );
          //if(!jQuery('body').hasClass('home') ){

              grid =  jQuery('.grid').imagesLoaded( function() {

              jQuery('.grid-item').each(function(){

                  // jQuery(this).width(  Math.floor(anchoGrid/2) );
                  //  ////console.log("ANCHO "+$(this).width() );
                });

                  grid.packery({gutter:0, percentPosition: true  });
                  grid.packery('shiftLayout');

              });


          //}




          jQuery('.transformar').not('.soyvideonotrans').each(function(){


            var imgData = jQuery(this).data('imgmvl');
            //console.log(imgData);
            jQuery(this).css('background-image','url('+ imgData+')');


          })

          setRootVars();


        //BOTONES PLAY

        if(jQuery('.play_iframe').length > 0){

          jQuery('.play_iframe').on('click', function(ev){
            //console.log('CLICK SACO MENÚ');
            //console.log(ev.currentTarget);
              ev.preventDefault();
              jQuery(ev.currentTarget).addClass('op_zero');
           });

        }

} //ON LOAD

function setRootVars(){

  if(ref_resp){

      ref_resp_w = ref_resp.getBoundingClientRect();

          ref_resp_w = ref_resp_w.width;

          root.style.setProperty('--anchoPant', window.innerWidth);

          if(ref_resp_w <= 70){

            jQuery('.set_resp').addClass('resp_col_w');

          }else{

            jQuery('.set_resp').removeClass('resp_col_w');

          }

          if(window.innerWidth>1480){
            jQuery('.set_resp').removeClass('resp_col_w');
          }



  }





  if(window.innerWidth<=768 ){

    jQuery('.set_espacio').addClass('d-none');
    jQuery('.set_movil').addClass('sgw_100');
    jQuery('#rrss_contenido').removeClass('d-none');


    jQuery('#row_contenido_2022').addClass('flex-wrap');

    jQuery('#sgw_espacio_redes').addClass('d-none');
    jQuery('#sgw_redes').addClass('d-none');


  }else{
    jQuery('.set_espacio').removeClass('d-none');
    jQuery('.set_movil').removeClass('sgw_100');
    jQuery('#row_contenido_2022').removeClass('flex-wrap');
  }


  if( window.innerWidth>768 && window.innerWidth<=1100){

    jQuery('#sgw_espacio_redes').addClass('d-none');
    jQuery('#sgw_redes').addClass('d-none');

    jQuery('#rrss_contenido').removeClass('d-none');
  }else if(window.innerWidth>1100){
    jQuery('#sgw_espacio_redes').removeClass('d-none');
    jQuery('#sgw_redes').removeClass('d-none');
    jQuery('#rrss_contenido').addClass('d-none');


  }



}


var set_resp = document.getElementsByClassName('.set_resp');
// set_resp = set_resp[0];
// var set_repW = set_resp.getBoundingClientRect();
// set_repW = set_repW.width;
var ref_resp = document.getElementById('ref_resp');

if(ref_resp){

    var ref_resp_w = ref_resp.getBoundingClientRect();
    ref_resp_w = ref_resp_w.width;

}







var root = document.querySelector(':root');

// set css variable
root.style.setProperty('--anchoPant', window.innerWidth);

// to get css variable from :root
//const color = getComputedStyle(root).getPropertyValue('--my-color'); // blue


  var canBeLoaded = true, // this param allows to initiate the AJAX call only if necessary
      bottomOffset = 2000; // the distance (in px) from the page bottom when you want to load more posts


  var catLoad = jQuery('.consult_term').attr('data-term');
  var taxo = 'works'; //jQuery('.resto_works').data('taxonomy');
  var f_rom = jQuery('.itm_proyecto').length;
  var gridData = jQuery('.resto_works').data('grid');




      jQuery(window).resize(function(){


         setRootVars();




        anchoItemCalc();

        console.log("resize");

        arreglosGrid();
        //arreglosSize();
        arregloCols();
      });


      function arregloCols(){


        //pongo_in_fixed

        if(jQuery('.col_desc .in_fixed').height()>jQuery('.col_imgs').height()  ||  jQuery('.col_desc .in_fixed').height() > jQuery(window).height() ){

            jQuery('.col_desc .in_fixed').removeClass('in_fixed');
          //////console.log("NO FIXED DESC " + jQuery('.col_desc').height() + " / IMGS "+jQuery('.col_imgs').height());

        }else{
         // ////console.log("FIXED");
        }

        jQuery('.in_fixed').each(function(){
          jQuery(this).width( jQuery(this).parent().width() ) ;
        });

        if( !jQuery('.pongo_in_fixed').hasClass('in_fixed') ){
            jQuery('.pongo_in_fixed').attr( 'style', '' ) ;
           jQuery('.pongo_in_fixed').css( 'width', 'auto !important' ) ;
           ////console.log("AUTO!");
        }

      }


function anchoItemCalc(){

        var totWin = jQuery(window).width()-30;


        if(  totWin < 480 ){

            anchoItem = totWin;

        }else if( jQuery(window).width()>480 && jQuery(window).width()<768   ){

            anchoItem = Math.floor(totWin/2);

        }else if( jQuery(window).width()>768 && jQuery(window).width()<1024   ){

           anchoItem = Math.floor(totWin/3);


        }else{

          anchoItem = Math.floor(totWin/4);

        }


        anchoItem = jQuery(window).width() ; //totWin;


        ////console.log("ANCHO ITEM", anchoItem);

}





function arreglosGrid(){



     /* FORA
  if(jQuery('body').hasClass('home')){



    console.log ('BOX  ARREGLOS ESTOOY EN HOMEEEE!!!');
   jQuery('.soy_a_video').each(function(index){

     console.log('> VIDEO!!!');
     console.log(jQuery(this) );

     var parentA = jQuery(this);

     var videoGran = jQuery(this).find('.video_gran');
     var videoMV =  jQuery(this).find('.video_movil');


     parentA.removeClass('video_img_vertical');
     parentA.removeClass('video_img_horizontal');

     parentA.removeClass('img_r');
     parentA.removeClass('img_q');


     jQuery(this).find('.video').each(function(index){

         // console.log('FIND VIDEO');
         // console.log(jQuery(this));

         // console.log(window.innerWidth);




         //atamgrid
         //aimgprop





         if(jQuery(window).width()<768){ //1024




           console.log('> parentA');
           console.log(parentA);


           var dataTamGrid = jQuery(videoMV).data('atamgrid');
           var dataImgProp = jQuery(videoMV).data('aimgprop');



           console.log('dataTamGrid');
           console.log(dataTamGrid);

           console.log('dataImgProp');
           console.log(dataImgProp);

           parentA.addClass(dataTamGrid);
           parentA.addClass(dataImgProp);

           console.log('NODENAME');

           console.log(jQuery(videoMV).prop('nodeName'));


           if( jQuery(videoMV).prop('nodeName') =='IMG'  ){

            parentA.height(jQuery(videoMV).height()) ;

           }else{





           }




         }else{


           var dataTamGrid = jQuery(videoGran).data('atamgrid');
           var dataImgProp = jQuery(videoGran).data('aimgprop');
           console.log(dataTamGrid);
           console.log(dataImgProp);


           parentA.addClass(dataTamGrid);
           parentA.addClass(dataImgProp);



         }



         //console.log('VOY  GRID !!!');


         jQuery('.grid').packery({gutter:0, percentPosition: true  });
         jQuery('.grid').packery('shiftLayout');



     });

   }); // jQuery(this).find('.video').each(function(index){


} */




 console.log('>>> arreglosGrid! XXX');



 if( !jQuery('body').hasClass('home')  ){

      if(jQuery('.img_q') ){

            jQuery('.img_q').each(function(){

              ////console.log("PRE ANCHO " +  jQuery(this).width()  );

              //jQuery(this).width( Math.floor(jQuery(this).width()) );

            // jQuery(this).width(anchoItem);

              ////console.log("ANCHOITEM "+ anchoItem +" ANCHO " +  jQuery(this).width()  );

              if(jQuery(this).hasClass('video_img_vertical')){

                /*
                var propV = jQuery(this).data('pvideo');

                //console.log('PROPV >>>>>>> ' + propV);

                jQuery(this).height( Math.floor( jQuery(this).width() / propV ) );
                */


                }else{

                  jQuery(this).height( Math.floor( jQuery(this).width() ) );

                }




            });

      }


 }




    var socVideo = false;

    if(jQuery('.img_r') && !jQuery('body').hasClass('home') ){

        jQuery('.img_r').each(function(){

          ////console.log("PRE ANCHO R " +  jQuery(this).width()  );

          //jQuery(this).width(Math.floor(jQuery(this).width()));

         // jQuery(this).width(anchoItem);

          ////console.log("ANCHO R " +  jQuery(this).width()  );




          if(jQuery(window).width() < 480){


              //console.log('MENOS DE 480');

              if(!jQuery(this).hasClass('descrip_texto') ) {


                  jQuery(this).height( Math.floor( (jQuery(this).width()*(1225/900))  ) + arreglo );



              }

          }else{

         console.log('>>> ANCHO ITEM >>>> ' + anchoItem);



            jQuery(this).width(anchoItem/2);

            //jQuery(this).height( Math.floor( (jQuery(this).width()*2)  ) + arreglo );

            jQuery(this).height( Math.floor( (jQuery(this).width()*(1225/900))  ) + arreglo );

            var propV = jQuery(this).data('pvideo');

            console.log('ARREGLO!!!!' +arreglo );

            console.log('PROPV >>>>>>> ' + propV);

            if(propV<1){

              var resultP =  Math.floor(jQuery(this).width() / propV ) ;

              jQuery(this).height( resultP );

              //console.log('PROPV >>>>>>> ' + propV +' PROP > 1');
              //console.log('RESULT ' + resultP);


              socVideo = true;

            }

            //jQuery(this).height( Math.floor( jQuery(this).width() / propV ) );



          }

          if(jQuery('body').hasClass('tax-works')){
              jQuery(this).height( Math.floor( (jQuery(this).width()*2)  ) + arreglo );
          }else{

            if(!socVideo){
                jQuery(this).height( Math.floor( (jQuery(this).width()*(1225/900))  ) + arreglo );

            }

          }


          //console.log("ALTO>>> "+jQuery(this).height()  );


        });
      }







      /*
      if(jQuery('.descrip_texto') && jQuery(window).height()>1280 ){

        //alert("ANCHO" + jQuery('.descrip_texto').width());

       // jQuery('.descrip_texto').height( Math.floor( jQuery('.descrip_texto').width()*2  )   + arreglo );

       jQuery('.descrip_texto').height( Math.floor( jQuery('.descrip_texto').width()*2  )  -2 );

      }else{

        alert("ANCHO " + jQuery('.descrip_texto').width());

        jQuery('.descrip_texto').height( Math.floor( jQuery('.descrip_texto').width()*2  )   + arreglo );

        alert("ALTO " + jQuery('.descrip_texto').height());

      }

       // alert("ALTO" + jQuery('.descrip_texto').height());

        if( jQuery('.descrip_texto').height() != jQuery('.descrip_texto').width()*2 ){

         // alert("DISTINTO");
        }*/

       // alert("ALTO 2" + jQuery('.descrip_texto').height());
}


var anchFotos = 0;
var anchoTotal = 0;
var sceneRow;
var controller;

var muevoHor;
/*
  $(function () { // wait for document ready
    // build scene
    var scene = new ScrollMagic.Scene({triggerElement: "#pin2"})
            .setPin("#pin2")
            .addIndicators({name: "2 (duration: 0)"}) // add indicators (requires plugin)
            .addTo(controller);
  });
*/

//row_gallery_scroll

function hazAbout(){

  window. scrollTo(0, 0);

  muevoHor = jQuery('.cont_images_muevo');


  if(jQuery(window).width()>=768){ //  if(window.innerWidth>768){


    hazScrollHorOK();



  } //  if(window.innerWidth>768){


  //GALERÏA SWIPER






      var swiperAbout = new Swiper('.galeria_1x1', {

        slidesPerView: 1,
        speed: 700,
        loop:true,

        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },


    });



    jQuery(window).resize(function(){



      if(sceneRow){


        console.log('RESIZEO ABOUT');
        sceneRow.destroy(true);
        sceneRow.remove();
        window. scrollTo(0, 0);
      }

      if(jQuery(window).width()>=768){ //  if(window.innerWidth>768){


        hazScrollHorOK();



      } //  if(window.innerWidth>768){


    })


}


function hazScrollHorOK(){


  console.log('HAGO SCROLL HOR!!!!!!!!!!!!!!!!');
  if(!controller){
    controller =  new ScrollMagic.Controller();
  }

  console.log('HAZ ABOUT!!');

  var total = jQuery('.row_gallery_scroll .cont_img_scroll_hor').size();
  jQuery('.row_gallery_scroll .cont_img_scroll_hor').each( function(index){
      if(index< total ){
        anchFotos += jQuery(this).width();
        console.log('ANCHO FOTO ' + jQuery(this).width());
      }

      anchoTotal += jQuery(this).width();

  });

  anchoTotal = jQuery('.cont_images_muevo').width();
  var anx = anchoTotal - jQuery('.cont_prin').width() ;

  anchFotos/=2;



    offset = 1000;

    var tmpGalObj = document.querySelector('#gallery_scroll_hor');
    tmpGalObj = tmpGalObj.getBoundingClientRect();

    console.log('>>>>>>>> tmpGalObj');
    console.log(tmpGalObj);
    console.log(jQuery('#gallery_scroll_hor').find('img').eq(0).height());



    offset =  tmpGalObj.y + jQuery('#gallery_scroll_hor').height()  ;



    console.log('>>>>>>>> offset');
    console.log(offset);

    // if(jQuery(window).height()>=992){


    //  console.log('>>>>>xxxx>>>>>> XXXXXXXXXXOOOFFFFSSSEEETTT ', offset);

    // }

    nuOffset = offset  - 130;

    if(jQuery(window).width()>992){

      nuOffset = offset  - 147;


      console.log('>>>>>xxxx>>>>>> XXXXXXXXXXOOOFFFFSSSEEETTT ', offset);

     }






   // offset = tmpGalObj.height;


  //https://scrollmagic.io/docs/ScrollMagic.Scene.html#constructor

      jQuery(function(){

        sceneRow = new ScrollMagic.Scene({triggerElement: "#contenido_about" ,
        duration : anchFotos ,
        triggerHook:0.95,
        offset:nuOffset })
                //  .setPin("#gallery_scroll_hor")
          .setPin("#contenido_about")//
          //.addIndicators({name: "1 (duration:"+ anchFotos +")"}) // add indicators (requires plugin)
          .addTo(controller);

          sceneRow.on("progress", function (event) {
            console.log("Scene progress changed to " + event.progress);

          // var pX = -100 *event.progress ;

            var py = -1* (event.progress  ) *  anx;

            console.log('py');
            console.log(py);

            jQuery('.cont_images_muevo').css('transform' , 'translateX(' +py+ 'px)')

          });


          sceneRow.on("leave", function (event) {
            console.log("Scene left.");
          // jQuery('.cont_images_muevo').css('transform' , 'translateX(' + (-1*anchoTotal) + window.innerWidth+ 'px)')
          });


            sceneRow.on("enter", function (event) {
            console.log("Scene entered.");
            });



            sceneRow.offset(nuOffset);

            console.log('!!!!!!! sceneRow >>>>>>');
            console.log(sceneRow.offset());

            console.log(   jQuery('.cont_img_scroll_hor').eq(0).offset().top  + '  ' +  jQuery('.cont_img_scroll_hor').eq(0).height()    );

            sceneRow.update();
            sceneRow.refresh();


      });



}
